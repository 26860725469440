// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start();
require("turbolinks").start();
require('@client-side-validations/client-side-validations');
require("@rails/activestorage").start();
require("channels");

// import CKEDITOR from '@ckeditor/ckeditor5-build-classic/build/ckeditor';
// require("packs/ckeditor/config");

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import $ from 'jquery';
import 'select2';                       // globally assign select2 fn to $ element
// import 'select2/dist/css/select2.css';  // optional if you have css loader
// $(() => {
//   $('.select2').select2({
//     tags: true
//   });
// });

$(document).on("ajax:complete", function(event) {
  var detail = event.detail[0];
  var alert_type, msg;
  msg = detail.getResponseHeader("X-Message");
  msg = decodeURIComponent(escape(msg));
  alert_type = 'alert-success';
  if (detail.getResponseHeader("X-Message-Type").indexOf("error") !== -1) {
    alert_type = 'alert-danger';
  }
  if (detail.getResponseHeader("X-Message-Type").indexOf("keep") !== 0) {
    if (msg) {
      $("#flash_hook").replaceWith("<div id='flash_hook'><div class='text-center'><div class='alert " + alert_type + "'> <button type='button' class='close' data-dismiss='alert'>&times;</button>" + msg + "</div> </div> </div>");
    }
    if (!msg) {
      return $("#flash_hook").replaceWith("<div id='flash_hook'></div>");
    }
  }
  $(".alert").fadeOut(10000);
  document.forms['new_message'].reset()
});

$(document).on('turbolinks:load', function() {
  $(".alert" ).fadeOut(5000);
  $( '.select2' ).select2({
    tags: true
  });
});
